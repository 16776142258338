import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@models/common/api-response.model';
import { PageableResponse } from '@models/common/pageable-response.model';
import { PageRequest, PageResponse } from 'pf-ui';
import { MessageService } from 'primeng/api';
import { catchError, map, Observable, retry, throwError, timer } from 'rxjs';
import { ErrorService } from '@services/error.service';
import { FilterValue } from '@models/tables/tables.model';

export type RequestOption = {
  retry?: boolean;
  catchError?: boolean;
  retryCount?: number;
  callbackError?: (error?: HttpErrorResponse) => Observable<never>;
};

export type PfPageRequest = PageRequest & {
  projection?: string;
};

const DEFAULT_RETRY_COUNT = 2;

@Injectable({
  providedIn: 'root',
})
export abstract class BaseService extends ErrorService {
  retryTimer: number = 2_000;

  protected options: RequestOption = {
    catchError: true,
    retry: true,
    retryCount: DEFAULT_RETRY_COUNT,
    callbackError: undefined,
  };

  protected constructor(
    protected http: HttpClient,
    protected override messageService: MessageService,
  ) {
    super(messageService);
  }

  executeRequest<T>(
    request: Observable<T>,
    options = this.options,
  ): Observable<T> {
    return request.pipe(
      retry({
        delay: (error, retryCount) => {
          if (
            error instanceof HttpErrorResponse &&
            (error.status === 401 ||
              error.status === 403 ||
              error.status === 406)
          ) {
            return throwError(() => error);
          }
          if (retryCount % 5 === 0) {
            this.showErrorToast(error);
          }
          return timer(retryCount * this.retryTimer);
        },
        count: options.retryCount ?? DEFAULT_RETRY_COUNT, // Maximum number of retry attempts
      }),
      catchError((error: HttpErrorResponse) => {
        if (options.callbackError === undefined) {
          return this.handleErrors(error);
        }
        return options.callbackError(error);
      }),
    );
  }

  public getHttpParams(event: PfPageRequest): HttpParams {
    let params = new HttpParams()
      .set('page', event?.page_number?.toString() ?? '0')
      .set('size', event?.page_size?.toString() ?? '10');

    if (event?.global_filter_fields != null && event?.global_filter != null) {
      params = params.set(
        event.global_filter_fields,
        this.getGlobalFilter(event.global_filter),
      );
    }

    if (event?.projection != null) {
      params = params.set('projection', event.projection);
    }

    if (event?.sort != null) {
      const sort = event?.sort;
      if (Array.isArray(sort)) {
        sort.forEach((item) => {
          params = params.set('sort', this.getSort(item, event.order));
        });
      } else {
        params = params.set('sort', this.getSort(sort, event.order));
      }
    }

    if (event?.filters != null) {
      const decodedFilters = decodeURIComponent(event?.filters);
      const filtersMap = JSON.parse(decodedFilters) as Record<
        string,
        FilterValue[]
      >;
      const filterQueryParams = Object.entries(filtersMap).reduce(
        (accu, [currentField, currentValue]) => {
          const innerValue = currentValue[0]?.value;
          const matchMode = currentValue[0]?.matchMode;
          const operator = currentValue[0]?.operator;
          let pfFilterValue: string[] = [];
          if (typeof innerValue === 'string' || innerValue instanceof Date) {
            pfFilterValue.push(innerValue as string);
          } else if (Array.isArray(innerValue)) {
            pfFilterValue = innerValue;
          }
          accu[currentField] = {
            value: pfFilterValue,
            matchMode: matchMode?.toUpperCase(),
            operator: operator,
          };
          return accu;
        },
        {} as Record<string, FilterValue>,
      );
      params = params.set('filters', JSON.stringify(filterQueryParams));
    }

    return params;
  }

  public getGlobalFilter(global_filter: string | string[]): string {
    return typeof global_filter === 'string'
      ? global_filter
      : global_filter.join(',');
  }

  public getSort(sort: string | string[], order?: 'ASC' | 'DESC'): string {
    const s = typeof sort === 'string' ? sort : sort.join(',');
    return `${s},${order ?? 'ASC'}`;
  }

  public executePaginatedRequest<T>(
    request: Observable<ApiResponse<{ [key: string]: T[] }>>,
    event?: PfPageRequest,
  ): Observable<PageResponse<T>> {
    return this.executeRequest<PageResponse<T>>(
      request.pipe(this.mapApiResponseToPageResponse<T>(event)),
    );
  }

  public executeNotPaginatedRequest<T>(
    request: Observable<ApiResponse<{ [key: string]: T[] }>>,
  ): Observable<T[]> {
    return this.executeRequest<T[]>(
      request.pipe(this.mapApiResponseToModel<T>()),
    );
  }

  mapApiResponseToPageResponse<T>(event?: PfPageRequest) {
    return (
      source: Observable<ApiResponse<{ [key: string]: T[] }>>,
    ): Observable<PageResponse<T>> =>
      source.pipe(
        map(
          (
            apiResponse: ApiResponse<{ [key: string]: T[] }>,
          ): PageResponse<T> => {
            const sort = event?.sort != null ? this.getSort(event.sort) : '';

            return {
              page_number: apiResponse.page?.number ?? 0,
              page_size: apiResponse.page?.size ?? 0,
              sort,
              order: event?.order ?? 'ASC',
              total_count: apiResponse.page?.totalElements ?? 0,
              result_count: apiResponse.page?.size ?? 10,
              result: apiResponse._embedded
                ? apiResponse._embedded[Object.keys(apiResponse._embedded)[0]]
                : [],
            };
          },
        ),
      );
  }

  mapApiResponseToModel<T>() {
    return (
      source: Observable<ApiResponse<{ [key: string]: T[] }>>,
    ): Observable<T[]> =>
      source.pipe(
        map((apiResponse: ApiResponse<{ [key: string]: T[] }>): T[] => {
          return apiResponse._embedded
            ? apiResponse._embedded[Object.keys(apiResponse._embedded)[0]]
            : [];
        }),
      );
  }

  mapPageableResponseToPageResponse<T>(event?: PfPageRequest) {
    return (
      source: Observable<PageableResponse<T>>,
    ): Observable<PageResponse<T>> =>
      source.pipe(
        map((page: PageableResponse<T>): PageResponse<T> => {
          let sort = '';

          if (event?.sort != null) {
            this.getSort(sort);
            sort =
              typeof event.sort === 'string'
                ? event.sort
                : event.sort.join(',');
          }

          return {
            page_number: page?.number ?? 0,
            page_size: page?.size ?? 0,
            sort,
            order: event?.order ?? 'ASC',
            total_count: page?.totalElements ?? 0,
            result_count: page.numberOfElements,
            result: page.content,
          };
        }),
      );
  }
}
