import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AdminRoute } from '@app/utils/routes';
import { TranslateService } from '@ngx-translate/core';
import { ErrorEnum } from '@utils/errorEnum';
import { KeycloakService } from 'keycloak-angular';
import { MessageService } from 'primeng/api';
import { delay, from, Observable, of, switchMap } from 'rxjs';

export type ExceptionError = {
  error?: string | { [k: string]: string };
  status: number;
};

@Injectable({
  providedIn: 'root',
})
export abstract class ErrorService {
  protected keycloak = inject(KeycloakService);
  public router = inject(Router);
  protected translateService = inject(TranslateService);

  private static readonly MESSAGE_ERROR_TYPE =
    "Une erreur s'est produite lors du traitement de votre demande. Veuillez réessayer plus tard.";

  private static readonly TITLE_ERROR_TYPE = 'Oups...';

  protected constructor(protected messageService: MessageService) {}

  public handleErrors(
    exception: {
      error?: string | object;
      status: number;
    } = {
      status: 500,
    },
  ): Observable<never> {
    switch (exception.status) {
      case 406:
      case 400:
        if (exception.error != null && typeof exception.error === 'object') {
          const ex = exception as ExceptionError;
          return this.handleValidationError(ex, exception.status === 406);
        }
        return this.handleServerError();
      case 403:
        return this.handleUnauthorizedError();
      case 401:
        return this.handleUnAuthenticatedError();
      default:
        return this.handleServerError();
    }
  }

  handleValidationError(
    exception: ExceptionError,
    withTranslate: boolean,
  ): Observable<never> {
    if (exception.error != null && typeof exception.error === 'object') {
      const error = exception.error as { [k: string]: string };
      const keyValuesError = Object.entries(error);

      if (keyValuesError.length <= 0) this.handleServerError();

      keyValuesError.forEach(([key, value]) => {
        const message = `${withTranslate ? this.translateService.instant('attributes.' + key) : key}: ${value.charAt(0).toUpperCase() + value.slice(1)}`;
        this.showErrorToast(
          message,
          this.getErrorTitle(ErrorEnum.VALIDATION_FAIL),
        );
      });
    }
    return of();
  }

  handleUnauthorizedError(): Observable<never> {
    this.showErrorToast(
      this.getErrorMessage(ErrorEnum.UNAUTHORIZED),
      this.getErrorTitle(ErrorEnum.UNAUTHORIZED),
    );
    return this.goBackToHome();
  }

  handleUnAuthenticatedError(): Observable<never> {
    this.showErrorToast(
      this.getErrorMessage(ErrorEnum.UNAUTHENTICATED),
      this.getErrorTitle(ErrorEnum.UNAUTHENTICATED),
    );
    return this.goBackToKeycloakLogin();
  }

  handleServerError(): Observable<never> {
    this.showErrorToast(
      this.getErrorMessage(ErrorEnum.SERVER_ERROR),
      this.getErrorTitle(ErrorEnum.SERVER_ERROR),
    );
    return of();
  }

  protected goBackToKeycloakLogin(): Observable<never> {
    return of(1).pipe(
      delay(3000),
      switchMap(() => from(this.keycloak.logout())),
      switchMap(() => of()),
    );
  }

  protected goBackToHome(): Observable<never> {
    return from(this.router.navigate([AdminRoute.Home])).pipe(
      switchMap(() => of()),
    );
  }

  private getErrorMessage(error: ErrorEnum): string {
    return (
      this.translateService.instant(`errors.${error}.message`) ??
      ErrorService.MESSAGE_ERROR_TYPE
    );
  }

  private getErrorTitle(error: ErrorEnum): string {
    return (
      this.translateService.instant(`errors.${error}.title`) ??
      ErrorService.TITLE_ERROR_TYPE
    );
  }

  public showErrorToast(detail: string, summary: string = 'Erreur'): void {
    this.messageService.add({
      severity: 'error',
      summary,
      detail,
    });
  }

  public showInfoToast(detail: string, summary: string = 'Information'): void {
    this.messageService.add({
      severity: 'info',
      summary,
      detail,
    });
  }
}
